import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import {
  HTMLAttributes,
  SyntheticEvent,
  useCallback,
  useRef,
  useState,
} from 'react'

import {
  ArticleContainer,
  ArticleHeading,
} from '@/features/article-sections'
import { DatoStructuredText } from '@/features/common/'
import { LoadingSpinner } from '@/features/common/'
import { useElementRect } from '@/hooks/useElementRect'
import {
  absoluteFill,
  animateIn,
  bezier,
  firstChild,
} from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { FormSelectField } from './FormSelectField'
import { FormTextArea } from './FormTextArea'
import { FormTextField } from './FormTextField'

export type ConditionsJSON = {
  fields: [
    {
      label: string
      showIf: {
        label: string
        equalsAny: string[]
      }
    },
  ]
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  data?: Queries.FormFragment | null
  layout: 'LIGHTBOX' | 'PAGE'
}

export const Form = ({
  data,
  layout,
  ...props
}: Props): JSX.Element => {
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null)
  const [successRef, setSuccessRef] = useState<HTMLElement | null>(null)

  const { height: formHeight } = useElementRect(formRef)
  const { height: successHeight } = useElementRect(successRef)

  const formData = useRef<{ [key: string]: string }>({})

  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const handleChange = useCallback((name: string, value: string) => {
    formData.current[name] = value
  }, [])

  const handleSubmit = () => {
    const submitFunction = async (data: {
      url: string
      method: string
      headers?: { [key: string]: string }
      body: string
    }) => {
      setSubmitting(true)
      try {
        const response = await fetch(data.url, {
          method: data.method,
          headers: data.headers,
          body: data.body,
        })
        if (response) {
          setSubmitting(false)
        }
        if (response.ok) {
          setSubmitted(true)
        } else {
          alert(
            `Sorry, there was an error submitting this form: ${response.status} ${response.statusText}`
          )
        }
      } catch (error) {
        alert(
          `Sorry, there was an error submitting this form: ${error}`
        )
      }
    }

    const encode = (data: { [key: string]: string | null }) => {
      return Object.keys(data)
        .map(key => {
          if (data[key] !== null) {
            return (
              encodeURIComponent(key) +
              '=' +
              encodeURIComponent(data[key] as string)
            )
          }
        })
        .join('&')
    }

    submitFunction({
      url: '/',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encode({
        'form-name': data?.formName || null,
        form: data?.formName || null,
        recipients: (
          data?.recipients?.map(recipient => recipient?.email) || []
        ).join(', '),
        ...formData.current,
      }),
    })
  }

  const styles = {
    article: css``,
    intro: css`
      max-width: 85ch;
      margin: 0 var(--margin);
      ${firstChild} {
        margin-top: 0;
      }
    `,
    wrapper: css`
      position: relative;
      display: grid;
      overflow: hidden;
      width: auto;
      height: auto;
      height: ${submitting && formHeight + 'px'};
      height: ${submitted && successHeight + 'px'};
      transition: all 300ms ease;
      margin: 0 var(--margin);
    `,
    form: css`
      max-width: 85ch;
      grid-area: 1 / 1 / 2 / 2;
      align-self: flex-start;
      display: flex;
      flex-wrap: wrap;
      --gap: 1em;
      gap: var(--gap);
      align-items: flex-start;
      justify-content: flex-start;
      opacity: 1;
      transition:
        opacity 200ms ease-out,
        transform 300ms ease-out;
      > input[type='hidden'] {
        display: none;
      }
      ${(submitting || submitted) &&
      css`
        opacity: 0;
        transform: translate3d(0, -6rem, 0);
      `}
    `,
    buttonWrap: css`
      display: flex;
      flex-basis: 100%;
      margin-top: 0.5em;
    `,
    button: css`
      align-self: stretch;
      font-size: 125%;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.05em;
      display: flex;
      align-items: center;
      position: relative;
      grid-column: 1 / -1;
      justify-self: flex-start;
      box-sizing: border-box;
      transition: all 200ms ease;
      color: ${colors.red};
      padding: 0.5em 0.75em;
      margin: 1em 0;
      border: 2px solid ${colors.red};
      &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 0%;
        top: 0;
        left: 0;
        background: ${colors.red};
        transition: width 200ms ${bezier.easeOut};
      }
      @media (hover: hover) {
        &&:hover {
          color: #fff;
          &:before {
            width: 100%;
          }
        }
      }

      form:invalid & {
        background: ${colors.gray75};
        border-color: ${colors.gray75};
        color: #ffffffcc;
        &:before {
          background: ${colors.gray65};
        }
        @media (hover: hover) {
          &:hover {
            border-color: ${colors.gray65};
          }
        }
      }
      span {
        position: relative;
      }
      input {
        ${absoluteFill}
        appearance: none;
        border: none;
        background: transparent;
        z-index: 2;
        border: 0;
        padding: 0;
        cursor: pointer;
      }
    `,
    successMessage: css`
      grid-area: 1 / 1 / 2 / 2;
      opacity: 0;
      transform: translate3d(0, 6rem, 0);
      animation: ${animateIn} 300ms ease-out forwards;
      align-self: flex-start;
      color: ${colors.gray30};
      border-top: 1px solid ${colors.gray75};
      padding-top: 1em;
      h2 {
        font-size: var(--fs-36);
        color: ${colors.red};
        margin: 0.5em 0;
      }
    `,
    spinner: css`
      grid-area: 1 / 1 / 2 / 2;
      aspect-ratio: 1 / 1;
      height: 4em;
      width: auto;
      max-height: 75%;
      align-self: center;
      justify-self: center;
      visibility: hidden;
      opacity: 0;
      transition: opacity 500ms ease;
      ${submitting &&
      css`
        visibility: visible;
        opacity: 1;
      `}
    `,
    fieldHeading: css`
      width: 100%;
      font-family: var(--ff-body);
      text-transform: uppercase;
      font-size: var(--fs-18);
      text-transform: uppercase;
      margin: 2em 0 0.25em;
      font-weight: 550;
    `,
  }

  let sectionName = null as null | string

  return (
    <ArticleContainer
      layout={layout}
      css={styles.article}
      {...props}
    >
      <ArticleHeading heading={data?.heading} />
      <div css={styles.intro}>
        <DatoStructuredText data={data?.intro} />
      </div>
      <div css={styles.wrapper}>
        <LoadingSpinner
          css={styles.spinner}
          color={'#000'}
        />
        {submitted ? (
          <div
            ref={node => setSuccessRef(node)}
            css={[styles.successMessage]}
          >
            <DatoStructuredText data={data?.successMessage} />
          </div>
        ) : (
          <form
            css={styles.form}
            ref={node => setFormRef(node)}
            name={data?.formName || undefined}
            data-netlify
            netlify-honeypot={'bot-field'}
            method="post"
            onSubmit={(e: SyntheticEvent) => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            <input
              type="hidden"
              name="bot-field"
              aria-hidden
              tabIndex={-1}
            />
            <input
              type="hidden"
              name="form-name"
              value={data?.formName || undefined}
              aria-hidden
              tabIndex={-1}
            />
            <input
              type="hidden"
              name="form"
              aria-hidden
              tabIndex={-1}
            />
            <input
              type="hidden"
              name="recipients"
              aria-hidden
              tabIndex={-1}
            />

            {data?.formFields?.map(field => {
              switch (field?.__typename) {
                case 'DatoCmsFormSectionHeading': {
                  sectionName = field.heading
                  return (
                    <h2
                      key={field.id}
                      css={styles.fieldHeading}
                    >
                      {field.heading}
                    </h2>
                  )
                }
                case 'DatoCmsFormTextField': {
                  return (
                    <FormTextField
                      data={field}
                      onChange={handleChange}
                      sectionName={sectionName}
                      key={field.id}
                    />
                  )
                }
                case 'DatoCmsFormTextArea': {
                  return (
                    <FormTextArea
                      data={field}
                      onChange={handleChange}
                      sectionName={sectionName}
                      key={field.id}
                    />
                  )
                }
                case 'DatoCmsFormSelectField': {
                  return (
                    <FormSelectField
                      data={field}
                      onChange={handleChange}
                      sectionName={sectionName}
                      key={field.id}
                    />
                  )
                }
              }
            })}
            <div css={styles.buttonWrap}>
              <div css={styles.button}>
                <span>{data?.submitButtonText}</span>
                <input
                  name="submit"
                  type="submit"
                  aria-label={data?.submitButtonText || 'Submit'}
                  value=""
                />
              </div>
            </div>
          </form>
        )}
      </div>
    </ArticleContainer>
  )
}

export const FormFragment = graphql`
  fragment Form on DatoCmsForm {
    __typename
    id: originalId
    heading
    intro {
      value
    }
    formFields {
      __typename
      ... on DatoCmsFormSectionHeading {
        id: originalId
        heading
      }
      ... on DatoCmsFormTextField {
        ...FormTextField
      }
      ... on DatoCmsFormSelectField {
        ...FormSelectField
      }
      ... on DatoCmsFormTextArea {
        ...FormTextArea
      }
    }
    submitButtonText
    successMessage {
      value
    }
    recipients {
      email
    }
    formName
    slug
    seoMetaTags {
      tags
    }
  }
`
